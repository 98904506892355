import React from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/pages/Home';
import WhoWeServe from './Components/pages/Services';
import WhyDunham from './Components/pages/WyAdvantage';
import Team from './Components/pages/Team';
import Contact from './Components/pages/Contact';
import Insights from './Components/pages/Insights';
import Service1 from './Components/pages/Services1';
import Service2 from './Components/pages/Services2';
import Service3 from './Components/pages/Services3';
import Service4 from './Components/pages/Services4';

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact Component={Home} />
        <Route exact path='/WhoWeServe' Component={WhoWeServe} />
        <Route exact path='/Service1' Component={Service1} />
        <Route exact path='/Service2' Component={Service2} />
        <Route exact path='/Service3' Component={Service3} />
        <Route exact path='/Service4' Component={Service4} />
        <Route exact path='/WhyDunham' Component={WhyDunham} />
        <Route exact path='/Team' Component={Team} />
        <Route exact path='/Insights' Component={Insights} />
        <Route exact path='/Contact' Component={Contact} />
        <Route path='/WhoWeServe/:section' element={<WhoWeServe />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
