import React from 'react';
import '../../App.css'
import Navbar from '../Navbar.js'
import ServiceItem2 from '../ServiceItem2.js';
import ServicesSection from '../ServiceSection2.js';
import Footer from '../Footer.js';

function Service2() {
    return (
        <>
            
            <ServicesSection/>
            <ServiceItem2/> 
            <Footer/>
        </>
    );
}


export default Service2;
  