import React from 'react';
import '../App.css';
import './ServicesSection.css';
import { Link } from 'react-router-dom';



function ServicesSection4() {
  return (
    <div className='hero-container'>
       
      <img src='/photos/images/Who We serve.png' />

      <div className="banner-content" style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <h1 className="banner-title" style={{ color: 'white', width: '100%' }}>Who We Serve - Private Family Trust Companies, Single-Family Offices</h1>
        <br />
        
      </div>


    </div>
  );
}

export default ServicesSection4;