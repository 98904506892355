import React from "react";
import "./ServiceItem.css";

function ServiceItem2() {
    return (
        <div className="items_item1">
            <div className="sv-container">
                <div className="sv-content">
                    <h1 className="sv-title">A Collaborative Approach</h1>
                    <p className="sv-des">
                        Leverage our in-house legal expertise and twenty-five years of experience 
                        harnessing the statutory climate in Nevada and Wyoming to assist families 
                        with significant wealth achieve their goals.
                    </p>
                </div>
            </div>

            <div className="sv-container">
                <div className="sv-content">
                    <h1 className="sv-title">Exemplary Stewardship</h1>
                    <p className="sv-des">
                        The investment teams, planners and business managers we work alongside 
                        set an exceptionally high standard of care. We value this commitment and 
                        establish continuity from design to implementation ensuring an unparalleled 
                        client experience.
                    </p>
                </div>
            </div>

            <div className="sv-container">
                <div className="sv-content">
                    <h1 className="sv-title">Multi-Jurisdictional</h1>
                    <p className="sv-des">
                        We recognize families with significant wealth often require optionality 
                        as it relates to where they ultimately domicile their assets. Our team can 
                        provide administrative support in leading statutory climates for generational, 
                        liquidity event, asset protection and philanthropic planning objectives.
                    </p>
                </div>
            </div>
            <br></br>
            {/* Get Started Button at the Bottom */}
            <div className="sv-button">
                <a href="/Contact" className="btn get-started">Get Started</a>
            </div>
        </div>
    );
}

export default ServiceItem2;
