import React from "react";
import "./ServiceItem.css";

function ServiceItem2() {
    return (
        <div className="items_item1">
            <div className="sv-container">
                <div className="sv-content">
                    <h1 className="sv-title">A Holistic Corporate Fiduciary</h1>
                    <p className="sv-des">
                        As a corporate trustee, we want the advice teams we work with to 
                        feel unbound by administrative capacity. From closely held business 
                        interests to private credit and IP, we provide stewardship to support 
                        the most sophisticated planning techniques.
                    </p>
                </div>
            </div>

            <div className="sv-container">
                <div className="sv-content">
                    <h1 className="sv-title">A Partner in Planning</h1>
                    <p className="sv-des">
                        Our professionals come to us from the drafting, compliance, tax and portfolio 
                        management disciplines and are well-versed in the issues facing lead advisors. 
                        Leverage our expertise during the design phase to ensure a seamless implementation 
                        of your trust-centric strategies.
                    </p>
                </div>
            </div>

            <div className="sv-container">
                <div className="sv-content">
                    <h1 className="sv-title">Access to Pro-Trust Situs</h1>
                    <p className="sv-des">
                        Dunham Private Trust serves advisors seeking access to leading multi-generational, 
                        asset protection and liquidity event planning jurisdictions. We have offices in 
                        both Nevada and Wyoming as well as Colorado and provide access to a network of tax, 
                        legal professionals across these markets.
                    </p>
                </div>
            </div>
            <br></br>
            {/* Get Started Button at the Bottom */}
            <div className="sv-button">
                <a href="/Contact" className="btn get-started">Get Started</a>
            </div>
        </div>
    );
}

export default ServiceItem2;
