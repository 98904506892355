import React, { useState, useRef, useEffect } from "react";
import { useParams } from 'react-router-dom';
import './ServiceItem.css';
import individualImage from "../Contents/Photos/1.png";
import attorneyImage from "../Contents/Photos/2.png";
import multifamImage from "../Contents/Photos/3.png";
import privateImage from "../Contents/Photos/4.png";

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}

function ServiceItem() {
    const { section } = useParams();
    const size = useWindowSize();
    const isMobile = size.width <= 768;

    // Set initial image state
    const [activeImage, setActiveImage] = useState(individualImage);

    // Create refs for each section
    const individualSectionRef = useRef(null);
    const attorneySectionRef = useRef(null);
    const multifamSectionRef = useRef(null);
    const privateSectionRef = useRef(null);

    // Function to get the navbar height dynamically
    const getNavbarHeight = () => {
        const navbar = document.querySelector('.navbar'); // Ensure navbar has this class
        if (navbar) {
            return navbar.offsetHeight;
        }
        return isMobile ? 60 : 80; // Default fallback values for mobile and desktop
    };

    // Scroll function with dynamic offset for navbar
    const scrollWithOffset = (ref) => {
        if (ref && ref.current) {
            const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
            const navbarHeight = getNavbarHeight();
            const additionalOffset = isMobile ? 20 : 0; // Adjust further if needed
            const offsetPosition = elementPosition - navbarHeight - additionalOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        if (section === 'individuals') {
            setActiveImage(individualImage);
            scrollWithOffset(individualSectionRef);
        } else if (section === 'attorneys') {
            setActiveImage(attorneyImage);
            scrollWithOffset(attorneySectionRef);
        } else if (section === 'multifamily') {
            setActiveImage(multifamImage);
            scrollWithOffset(multifamSectionRef);
        } else if (section === 'private') {
            setActiveImage(privateImage);
            scrollWithOffset(privateSectionRef);
        }
    }, [section]);

    return (
        <div className="items_item1">
            
            {!isMobile ? (
                <div className="box-container">
                    <div className="item-content">
                    <h2 className="item-title">Who We Serve</h2> {/* Ensure this remains for both mobile and desktop */}
                        <ul className="item-description">
                            <li onMouseEnter={() => setActiveImage(individualImage)} onClick={() => scrollWithOffset(individualSectionRef)}>
                                Individuals and Families with Significant Wealth
                            </li>
                            <li onMouseEnter={() => setActiveImage(attorneyImage)} onClick={() => scrollWithOffset(attorneySectionRef)}>
                                Attorneys, Tax and Business Managers
                            </li>
                            <li onMouseEnter={() => setActiveImage(multifamImage)} onClick={() => scrollWithOffset(multifamSectionRef)}>
                                Multi-Family Offices, Private Wealth Teams and Investment Advisories
                            </li>
                            <li onMouseEnter={() => setActiveImage(privateImage)} onClick={() => scrollWithOffset(privateSectionRef)}>
                                Private Family Trust Companies, Single-Family Offices
                            </li>
                        </ul>
                    </div>
                    <div className="item-image-container">
                        <img src={activeImage} alt="Selected" className="item-image" />
                    </div>
                </div>
            ) : (
                <div className="mobile-options">
                    <h2 className="item-title">Who We Serve</h2> {/* Ensure this remains for both mobile and desktop */}
                    <div className="mobile-option" onClick={() => scrollWithOffset(individualSectionRef)}>
                        <img src={individualImage} alt="Individuals" className="item-image" />
                        <p>Individuals and Families with Significant Wealth</p>
                    </div>
                    <div className="mobile-option" onClick={() => scrollWithOffset(attorneySectionRef)}>
                        <img src={attorneyImage} alt="Attorneys" className="item-image" />
                        <p>Attorneys, Tax and Business Managers</p>
                    </div>
                    <div className="mobile-option" onClick={() => scrollWithOffset(multifamSectionRef)}>
                        <img src={multifamImage} alt="Multi-Family Offices" className="item-image" />
                        <p>Multi-Family Offices, Private Wealth Teams and Investment Advisories</p>
                    </div>
                    <div className="mobile-option" onClick={() => scrollWithOffset(privateSectionRef)}>
                        <img src={privateImage} alt="Private Family Trust" className="item-image" />
                        <p>Private Family Trust Companies, Single-Family Offices</p>
                    </div>
                </div>
            )}

            {/* Sections */}
            <div ref={individualSectionRef} className="pink-container">
                <div className="pink-content">
                    <h1 className="pink-title">Individuals and Families with Significant Wealth</h1>
                    <p className="pink-subtitle">Worry Less, Live Fully</p>
                </div>
                <div className="pink-button">
                    <a href="/Service1" className="btn get-started">Get Started</a>
                </div>
            </div>

            <div ref={attorneySectionRef} className="pink-container">
                <div className="pink-content">
                    <h1 className="pink-title">Attorneys, Tax and Business Managers</h1>
                    <p className="pink-subtitle">A Partner in Building Durable Wealth Plans</p>
                </div>
                <div className="pink-button">
                    <a href="/Service2" className="btn get-started">Get Started</a>
                </div>
            </div>

            <div ref={multifamSectionRef} className="pink-container">
                <div className="pink-content">
                    <h1 className="pink-title">Multi-Family Offices, Private Wealth Teams and Investment Advisories</h1>
                    <p className="pink-subtitle">Trust Expertise Where it Matters</p>
                </div>
                <div className="pink-button">
                    <a href="/Service3" className="btn get-started">Get Started</a>
                </div>
            </div>

            <div ref={privateSectionRef} className="pink-container">
                <div className="pink-content">
                    <h1 className="pink-title">Private Family Trust Companies, Single-Family Offices</h1>
                    <p className="pink-subtitle">Fiduciary Solutions for Family Trusts</p>
                </div>
                <div className="pink-button">
                    <a href="/Service4" className="btn get-started">Get Started</a>
                </div>
            </div>
        </div>
    );
}

export default ServiceItem;



