import React from "react";
import "./ServiceItem.css";

function ServiceItem2() {
    return (
        <div className="items_item1">
            <div className="sv-container">
                <div className="sv-content">
                    <h1 className="sv-title">Administrative and Operational Support</h1>
                    <p className="sv-des">
                        Dunham Private Trust functions as an outsourced back office solution 
                        in the most favorable jurisdictions for establishing both SFOs and PFTCs. 
                        Like an external CFO, we manage day-to-day operations including trust 
                        accounting, record-keeping and financial reporting
                    </p>
                </div>
            </div>

            <div className="sv-container">
                <div className="sv-content">
                    <h1 className="sv-title">Regulatory Compliance and Governance</h1>
                    <p className="sv-des">
                        We work with your trusted legal counsel to draft and implement the 
                        operational and investment policies and procedures that align with the 
                        needs of each family. Our team ensures ongoing maintenance for robust 
                        family governance and philanthropic structures,
                    </p>
                </div>
            </div>

            <div className="sv-container">
                <div className="sv-content">
                    <h1 className="sv-title">Fiduciary Oversight and Risk Management</h1>
                    <p className="sv-des">
                        As a corporate fiduciary. we bring an element of impartiality to the 
                        task of adhering to a family’s goal and objectives and ensuring those 
                        goals are effectively communicated through their trust. We provide an 
                        additional layer of investment oversight, tax reporting and risk 
                        management while assessing ongoing asset protection related issues.
                    </p>
                </div>
            </div>
            <br></br>
            {/* Get Started Button at the Bottom */}
            <div className="sv-button">
                <a href="/Contact" className="btn get-started">Get Started</a>
            </div>
        </div>
    );
}

export default ServiceItem2;
