import React from 'react';
import './Footer.css';

import { Link } from 'react-router-dom';
import logo from '../Contents/Logo/Logofooter.svg';

function Footer() {
  return (
    <div className='footer-container'>
      

      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          
          {/* New Column First */}
          <div className='footer-link-items bold-column'>
          <div className='footer-logo'>
            <img src={logo} alt="Logo" />
          </div>
          {/* <br></br>
            <Link to='https://www.dunhamtrust.com/Pages/Disclaimers'>DISCLAIMERS</Link>
            <Link to='https://www.dunhamtrust.com/Pages/AML'>ANTI-MONEY LAUNDERING</Link>
            <Link to='https://www.dunhamtrust.com/Pages/PrivacyPolicy'>PRIVACY POLICY</Link>
            <Link to='https://www.dunhamtrust.com/Pages/BCP'>BUSINESS CONTINUITY</Link> */}
          </div>

          <div className='footer-link-items'>
          <Link to='https://www.dunhamtrust.com/Pages/Disclaimers'>DISCLAIMERS</Link>
            <Link to='https://www.dunhamtrust.com/Pages/AML'>ANTI-MONEY LAUNDERING</Link>
            <Link to='https://www.dunhamtrust.com/Pages/PrivacyPolicy'>PRIVACY POLICY</Link>
            <Link to='https://www.dunhamtrust.com/Pages/BCP'>BUSINESS CONTINUITY</Link>
          </div>


          <div className='footer-link-items'>
            <Link to='/WhyDunham'>Why Dunham</Link>
          
            <Link to='/WhoWeServe'>Who We Serve</Link>
          
            <Link to='/Team'>Who We Are</Link>
         
            <Link to='/Insights'>Insights</Link>
          </div>

          <div className='footer-link-items'>
            <h3>Contact Us</h3>
            
            <a>Phone: (888) 438-6426</a>
            <a>Fax: (775) 826-7904</a>
          </div>
        </div>

        
      </div>

      <section className='social-media'>
        <div className='social-media-wrap'>
         
          
          <small className='website-rights'>
            Dunham Private Trust is the Wyoming division of Dunham Trust Company. Trust services offered through Dunham Trust Company, an affiliated Nevada Trust Company.
            <br/><br/>
            ©2024 Dunham Private Trust Company. All Rights Reserved
          </small>
          
        </div>
      </section>
    </div>
  );
}

export default Footer;
